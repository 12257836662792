export const initDarkMode = () => {
  const root = document.querySelector(':root');
  const trigger = document.querySelector('[data-dark-mode-trigger]');

  trigger.addEventListener('click', (e) => {
    if (trigger.checked) {
      root.style.setProperty('--main-color', '#000')
      localStorage.setItem("darkmode", "true");
    } else {
      root.style.setProperty('--main-color', '#2D4856');
      localStorage.setItem("darkmode", "false");
    }
  })

  if (localStorage.getItem("darkmode") == "true") {
    root.style.setProperty('--main-color', '#000')
    trigger.checked = true
  }
}