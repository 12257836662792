export const addContent = (firstContent, secondContent) => {
  const contentFirst = document.querySelector("[data-about-content-first]");
  const contentSecond = document.querySelector("[data-about-content-second]");

  let contentFirstInner = `
    <div class="container-fluid">
      <div class="about-content-first-content">
        <p>${firstContent.content}</p>
      </div>
      <figure class="about-content-first-image">
        <img
          class="img-fluid defer"
          data-src="${firstContent.image}"
          alt="Sobre o Studio MOU">
      </figure>
    </div>
  `;

  let contentSecondInner = `
    <div class="page-detail">
      <span></span><span></span><span></span><span></span><span></span>
    </div>
    <div class="about-content-second-content">
      <p>${secondContent.content}</p>
      <a
        data-hoverable
        href="./contato.html"
        class="button">
        Entre em contato
        <div class="button-arrow"></div>
      </a>
    </div>
    <figure class="about-content-second-image">
      <img
        class="img-fluid defer"
        data-src="${secondContent.image}"
        alt="Sobre o Studio MOU">
    </figure>
  `;

  if (contentFirst) contentFirst.innerHTML = contentFirstInner;

  if (contentSecond) contentSecond.innerHTML = contentSecondInner;
};
