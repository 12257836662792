import { addAddress } from "../modules/general/addAddress";
import { addEmail } from "../modules/general/addEmail";
import { addPhones } from "../modules/general/addPhones";
import { createSocial } from "../modules/general/createSocial";
import { createPhones } from "../modules/general/createPhones";
import { baseUrl } from "../vendors/util"
import { addSocial } from "../modules/general/addSocial";
import { cursor } from "../modules/general/cursor";
import { preloaderClose } from "../animations/preloaderClose";

export const initFetchContact = () => {
  async function fetchContact(url) {
    const contactResponse = await fetch(url);
    const contactJSON = await contactResponse.json();

    addEmail(contactJSON[0].email, '[data-contact-email]')
    addAddress(contactJSON[0].address, '[data-contact-address]')

    contactJSON[0].phones.forEach((phone) => {
      const list = createPhones(phone)
      addPhones(list, '[data-contact-phones]')
    })

    contactJSON[0].social.forEach((itemSocial) => {
      const list = createSocial(itemSocial)
      addSocial(list, '[data-contact-social]')
    })
  }

  fetchContact(baseUrl + 'general').then(() => {
    cursor()
    preloaderClose()
  })
}