import { addPageProjects } from "../modules/projects/addPageProjects";
import { createPageProjects } from "../modules/projects/createPageProjects";
import { baseUrl } from "../vendors/util"
import { defer } from "../modules/general/defer"
import { InitFilterCategory } from "../modules/projects/filterCategory";
import { cursor } from "../modules/general/cursor";
import { preloaderClose } from "../animations/preloaderClose";

export const initFetchProjects = () => {
  async function fetchProjects(url) {
    const projectsResponse = await fetch(url);
    const projectsJSON = await projectsResponse.json();

    projectsJSON.forEach((project) => {
      const list = createPageProjects(project);
      addPageProjects(list);
    })
  }

  fetchProjects(baseUrl + 'projects').then(() => {
    defer()
    cursor()

    const filterCategory =
      new InitFilterCategory(
        '[data-category-filter]'
      );
    filterCategory.init()

    preloaderClose()
  })
}