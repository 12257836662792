import { preloaderClose } from "../animations/preloaderClose";
import { cursor } from "../modules/general/cursor";
import { defer } from "../modules/general/defer";
import { addEmployees } from "../modules/home/addEmployees";
import { addPosts } from "../modules/home/addPosts";
import { addProjects } from "../modules/home/addProjects";
import { createAbout } from "../modules/home/createAbout";
import { createBlog } from "../modules/home/createBlog";
import { createBlogPosts } from "../modules/home/createBlogPosts";
import { createContact } from "../modules/home/createContact";
import { createHero } from "../modules/home/createHero";
import { createProjects } from "../modules/home/createProjects";
import { createTeam } from "../modules/home/createTeam";
import { initHomeHeroSlide } from "../modules/home/initHomeHeroSlide";

import { baseUrl } from "../vendors/util"

export const initFetchHome = () => {
  async function fetchHome(url) {
    const homeResponse = await fetch(url);
    const homeJSON = await homeResponse.json();

    const hero = {
      gallery: homeJSON[0].hero_gallery,
      featured: homeJSON[0].hero_featured,
      button: homeJSON[0].hero_button
    }

    const about = {
      content: homeJSON[0].about_content,
      button: homeJSON[0].about_button
    }

    const contact = {
      title: homeJSON[0].contact_title,
      image: homeJSON[0].contact_image
    };

    const blog = {
      title: homeJSON[0].blog_title,
      posts: homeJSON[0].blog_posts
    }

    homeJSON[0].projects.forEach((project, index) => {
      const list = createProjects(project, index)
      addProjects(list)
    })

    homeJSON[0].team.forEach((employee) => {
      const list = createTeam(employee)
      addEmployees(list)
    })

    blog.posts.forEach((post) => {
      const list = createBlogPosts(post)
      addPosts(list)
    })

    createHero(hero)
    createAbout(about)
    createContact(contact)
    createBlog(blog)
  }

  fetchHome(baseUrl + 'home').then(() => {
    defer();
    cursor()
    initHomeHeroSlide();
    preloaderClose()
  })
}