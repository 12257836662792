export const createHeroSlide = (slide) => {
  const heroSlideItem = document.createElement('li');
  heroSlideItem.classList.add('glide__slide');

  heroSlideItem.innerHTML = `
    <figure class="home-hero-slide-figure">
      <div class="home-hero-slide-figure-overlay"></div>
      <img
        class="img-fluid defer"
        data-src="${slide}"
        alt="">
    </figure>
  `;

  return heroSlideItem;
}