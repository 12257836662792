export default class InitHorizontalDrag {

  constructor(item) {
    this.draggingList = document.querySelectorAll(item)
  }

  addDragToList() {
    let isDown = false;
    let startX;
    let scrollLeft;

    this.draggingList.forEach(list => {
      list.addEventListener('mousedown', (e) => {
        isDown = true;
        list.classList.add('active');
        startX = e.pageX - list.offsetLeft;
        scrollLeft = list.scrollLeft;
      });

      list.addEventListener('mouseleave', () => {
        isDown = false;
        list.classList.remove('active');
      });

      list.addEventListener('mouseup', () => {
        isDown = false;
        list.classList.remove('active');
      });

      list.addEventListener('mousemove', (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - list.offsetLeft;
        const walk = (x - startX) * 2;
        list.scrollLeft = scrollLeft - walk;
      });
    });
  }

  init() {
    if (this.draggingList) {
      this.addDragToList();
    }
  }
}