export const createRelated = (item) => {
  const relatedItem = document.createElement('li');

  let relatedItemContent = `
    <article class="project-related-item">
      <figure class="project-related-item-figure">
        <img
          class="img-fluid defer"
          data-src="${item.image}"
          alt="${item.title}">
      </figure>
      <div class="project-related-item-content">
        <h1 class="project-related-item-title">${item.title}</h1>
        <p class="project-related-item-description">${item.description}</p>
        <a
          data-hoverable
          title="${item.title}"
          href="./projeto.html?slug=${item.slug}"
          class="button-shrunk">
          <span>Ver Projeto</span>
          <div class="button-arrow"></div>
        </a>
      </div>
    </article>
  `;

  relatedItem.innerHTML = relatedItemContent;

  return relatedItem;
}