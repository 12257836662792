export const initPreloader = () => {
  let i = 0;

  const counter = () => {
    if (i == 0) {
      i = 1;
      var elem = document.querySelector("[data-preloader-counter] span");
      var count = 1;
      var setCounter = setInterval(frame, 15);
      function frame() {
        if (count >= 99) {
          clearInterval(setCounter);
          i = 0;
        } else {
          count++;
          elem.innerHTML = count;
        }
      }
    }
  }

  counter();
}