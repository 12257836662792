import { baseUrl } from "../vendors/util"
import { defer } from "../modules/general/defer";
import { addHero } from "../modules/single/addHero";
import { createRelated } from "../modules/single/createRelated";
import { addRelated } from "../modules/single/addRelated";
import { preloaderClose } from "../animations/preloaderClose";

export const initFetchSingle = () => {
  async function fetchSingle(url) {
    const singleResponse = await fetch(url);
    const singleJSON = await singleResponse.json();

    let hero = {
      title: singleJSON.title,
      image: singleJSON.image,
      date: singleJSON.date
    }

    addHero(hero)

    const singleContent = document.querySelector('[data-single-content]');

    singleContent.innerHTML = singleJSON.content;

    singleJSON.related.forEach((item) => {
      const list = createRelated(item);
      addRelated(list);
    })
  }

  const urlParams = new URLSearchParams(window.location.search);
  const slug = urlParams.get('slug');

  fetchSingle(baseUrl + 'post/' + slug).then(() => {
    defer()
    preloaderClose()
  })
}