export const createTeam = (employee) => {
  const teamItem = document.createElement('li');

  teamItem.innerHTML = `
    <article class="home-team-item">
      <figure class="home-team-item-figure">
        <img
          class="img-fluid defer"
          data-src="${employee.image}"
          alt="">
      </figure>
      <div class="home-team-item-content">
        <h1>${employee.title}</h1>
        ${employee.description}
      </div>
    </article>
  `;

  return teamItem;
}