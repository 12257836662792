export const createPageTeam = (person) => {
  const teamItem = document.createElement('li');
  teamItem.classList.add('team-list-item');

  teamItem.innerHTML = `
    <article>
      <figure class="team-list-item-figure">
        <img
          class="img-fluid defer"
          data-src="${person.image}"
          alt="${person.title}">
      </figure>
      <div class="team-list-item-content">
        <h1 class="team-list-item-title">${person.title}</h1>
        <div class="team-list-item-about">${person.description}</div>
        <div class="team-list-item-description">
          ${person.about}
        </div>
      </div>
    </article>
  `;

  return teamItem;
}