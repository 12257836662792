import { baseUrl } from "../vendors/util"
import { defer } from "../modules/general/defer";
import { preloaderClose } from "../animations/preloaderClose";

export const initFetchPage = () => {
  async function fetchPage(url) {
    const pageResponse = await fetch(url);
    const pageJSON = await pageResponse.json();

    const pageTitle = document.querySelector('[data-page-title]');
    const pageContent = document.querySelector('[data-page-content]');

    pageTitle.innerHTML = pageJSON[0].title;
    pageContent.innerHTML = pageJSON[0].content;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const slug = urlParams.get('slug');

  fetchPage(baseUrl + 'page/' + slug).then(() => {
    defer()
    preloaderClose()
  })
}