export const addComparison = (comparison) => {
  const projectComparison = document.querySelector('[data-project-comparison]');
  const before = document.querySelector('[data-project-comparison-before]');
  const after = document.querySelector('[data-project-comparison-after]');

  if (comparison.before && before && comparison.after && after) {
    before.setAttribute('data-src', comparison.before)
    after.setAttribute('data-src', comparison.after)

    return;
  }
  projectComparison.classList.add('hide')
}