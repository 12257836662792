export const createPhones = (phone) => {
  const phoneItem = document.createElement('p');

  phoneItem.innerHTML = `
    <a href="${phone.link}" data-hoverable>
      ${phone.number}
    </a>
  `;

  return phoneItem;
}