import { gsap } from 'gsap'
import Scrollbar from 'smooth-scrollbar'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

const scroller = document.querySelector('.scroller');

export const bodyScrollBar = Scrollbar.init(scroller, {
  damping: 0.09,
  delegateTo: document,
  alwaysShowTracks: false,
  // continuousScrolling: false
});

ScrollTrigger.scrollerProxy(".scroller", {
  scrollTop(value) {
    if (arguments.length) {
      bodyScrollBar.scrollTop = value;
    }
    return bodyScrollBar.scrollTop;
  }
});

bodyScrollBar.addListener(ScrollTrigger.update);

ScrollTrigger.defaults({ scroller: scroller });