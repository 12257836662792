export const createPagePosts = (post) => {
  const postItem = document.createElement('li');

  postItem.innerHTML = `
    <article class="blog-item">
      <figure class="blog-item-figure">
        <img
        class="img-fluid defer"
        data-src="${post.image}"
        alt="${post.title}">
      </figure>
      <a href="./post.html?slug=${post.slug}">
        <div class="blog-item-meta">
          <p class="blog-item-date">${post.date}</p>
        </div>
        <h1 class="blog-item-title">${post.title}</h1>
      </a>
    </article>
  `;

  return postItem;
}