import { addAddress } from "../modules/general/addAddress";
import { addEmail } from "../modules/general/addEmail";
import { addPhones } from "../modules/general/addPhones";
import { createSocial } from "../modules/general/createSocial";
import { createPhones } from "../modules/general/createPhones";
import { baseUrl } from "../vendors/util"
import { addCookies, checkCookiesIsAccepted } from "../modules/general/cookies";
import { addSocial } from "../modules/general/addSocial";
import { cursor } from "../modules/general/cursor";

export const initFetchGeneral = () => {
  async function fetchGeneral(url) {
    const generalResponse = await fetch(url);
    const generalJSON = await generalResponse.json();

    addEmail(generalJSON[0].email, '[data-footer-email]')
    addAddress(generalJSON[0].address, '[data-footer-address]')

    generalJSON[0].phones.forEach((phone) => {
      const list = createPhones(phone)
      addPhones(list, '[data-footer-phones]')
    })

    generalJSON[0].social.forEach((itemSocial) => {
      const list = createSocial(itemSocial)
      addSocial(list, '[data-footer-social]')
      addSocial(list, '[data-header-social]')
    })

    addCookies(generalJSON[0].cookies_message)
  }

  fetchGeneral(baseUrl + 'general').then(() => {
    checkCookiesIsAccepted()
    cursor()
  })
}