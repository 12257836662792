export const createBlogPosts = (post) => {
  const postItem = document.createElement('li');

  postItem.innerHTML = `
    <article class="home-blog-item">
      <figure class="home-blog-item-figure">
        <img
          class="img-fluid defer"
          data-src="${post.image}"
          alt="${post.title}">
      </figure>
      <a href="${post.slug}">
        <div class="home-blog-item-meta">
          <p class="home-blog-item-date">${post.date}</p>
        </div>
        <h1 class="home-blog-item-title">${post.title}</h1>
      </a>
    </article>
  `;

  return postItem;
}