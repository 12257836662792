import cursor from '../../components/global/cursor.html';
import preloader from '../../components/global/preloader.html';
import cookies from '../../components/global/cookies.html';

import header from '../../components/layout/header.html';

import hero from '../../components/contact/hero.html';
import list from '../../components/contact/form.html';

import footer from '../../components/layout/footer.html';

export const contactRoot = document.getElementById("contactRoot");
const contactHead = document.getElementById("contactHead");
const contactContent = document.getElementById("contactContent");
const contactFoot = document.getElementById("contactFoot");

export const initContactPage = () => {
  if (contactRoot && contactHead && contactContent && contactFoot) {
    contactRoot.innerHTML =
      cursor +
      preloader +
      cookies;

    contactHead.innerHTML =
      header;

    contactContent.innerHTML =
      hero +
      list;

    contactFoot.innerHTML =
      footer;
  }
}