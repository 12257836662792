import "../sass/style.scss";

import { initHomePage, homeRoot } from "./pages/home";
import { initAboutPage, aboutRoot } from "./pages/about";
import { initTeamPage, teamRoot } from "./pages/team";
import { initProjectsPage, projectsRoot } from "./pages/projects";
import { initProjectPage, projectRoot } from "./pages/project";
import { initBlogPage, blogRoot } from "./pages/blog";
import { initContactPage, contactRoot } from "./pages/contact";
import { initSinglePage, singleRoot } from "./pages/single";

import { cursor } from "./modules/general/cursor";
import "./modules/smoothScroll";

import { menu } from "./animations/menu";
import { defer } from "./modules/general/defer";
import InitHorizontalDrag from "./modules/horizontalDrag";
import { addTransparentClassHeader } from "./modules/addTransparentClassHeader";
import { initDarkMode } from "./modules/initDarkMode";
import { getCurrentYear } from "./modules/getCurrentYear";
import { initFetchHome } from "./services/home";
import { initFetchGeneral } from "./services/general";
import { initFetchProjects } from "./services/projects";
import { initFetchPosts } from "./services/posts";
import { initFetchTeam } from "./services/team";
import { initFetchAbout } from "./services/about";
import { initFetchProject } from "./services/project";
import { MailSender } from "./modules/mail/mailSender";
import { initFetchSingle } from "./services/single";
import { initPage, pageRoot } from "./pages/page";
import { initFetchContact } from "./services/contact";
import { initFetchPage } from "./services/page";
import { createMasks } from "./modules/general/createMasks";
import { initPreloader } from "./modules/general/preloader";


window.addEventListener("load", () => {
  if (homeRoot) {
    initHomePage();

    initFetchHome();

    const homeTeamDragList =
      new InitHorizontalDrag('[data-home-team-list]');
    homeTeamDragList.init()

    const homeBlogDragList =
      new InitHorizontalDrag('[data-home-blog-list]');
    homeBlogDragList.init()

    const contactForm = document.querySelector('[data-contact-form]');

    const contactFormSend = new MailSender(
      contactForm,
      {
        'name': document.querySelector('input[name=name]'),
        'email': document.querySelector('input[name=email]'),
        'phone': document.querySelector('input[name=phone]'),
        'message': document.querySelector('input[name=message]')
      },
      './sendContact.php',
      '[data-contact-form-response]'
    );

    contactForm.addEventListener('submit', (e) => {
      contactFormSend.submit(e);
    })

    createMasks()
  }

  if (aboutRoot) {
    initAboutPage();

    initFetchAbout();
  }

  if (teamRoot) {
    initTeamPage();

    initFetchTeam();
  }

  if (projectsRoot) {
    initProjectsPage();

    initFetchProjects();
  }

  if (projectRoot) {
    initProjectPage();

    initFetchProject();

    addTransparentClassHeader();
  }

  if (blogRoot) {
    initBlogPage()

    initFetchPosts()
  }

  if (singleRoot) {
    initSinglePage()

    initFetchSingle()

    const singleRelatedDragList =
      new InitHorizontalDrag('[data-single-related-list]');

    singleRelatedDragList.init()
  }

  if (contactRoot) {
    initContactPage()
    initFetchContact()

    addTransparentClassHeader();

    const contactForm = document.querySelector('[data-contact-form]');

    const contactFormSend = new MailSender(
      contactForm,
      {
        'name': document.querySelector('input[name=name]'),
        'email': document.querySelector('input[name=email]'),
        'phone': document.querySelector('input[name=phone]'),
        'message': document.querySelector('input[name=message]')
      },
      './sendContact.php',
      '[data-contact-form-response]'
    );

    contactForm.addEventListener('submit', (e) => {
      contactFormSend.submit(e);
    })

    createMasks()
  }

  if (pageRoot) {
    initPage()
    initFetchPage()
  }

  defer();
  initPreloader()
  cursor();

  menu();
  initDarkMode();
  getCurrentYear();

  initFetchGeneral();

  const newsletterForm = document.querySelector('[data-newsletter-form]');

  const newsletterFormSend = new MailSender(
    newsletterForm,
    {
      'email': document.querySelector('input[name=email]')
    },
    './sendNews.php',
    '[data-newsletter-form-response]'
  );

  newsletterForm.addEventListener('submit', (e) => {
    newsletterFormSend.submit(e);
  })


});
