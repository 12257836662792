import cursor from '../../components/global/cursor.html';
import preloader from '../../components/global/preloader.html';
import cookies from '../../components/global/cookies.html';

import header from '../../components/layout/header.html';

import hero from '../../components/about/hero.html';
import content from '../../components/about/content.html';

import footer from '../../components/layout/footer.html';

export const aboutRoot = document.getElementById("aboutRoot");
const aboutHead = document.getElementById("aboutHead");
const aboutContent = document.getElementById("aboutContent");
const aboutFoot = document.getElementById("aboutFoot");

export const initAboutPage = () => {
  if (aboutRoot && aboutHead && aboutContent && aboutFoot) {
    aboutRoot.innerHTML =
      cursor +
      preloader +
      cookies;

    aboutHead.innerHTML =
      header;

    aboutContent.innerHTML =
      hero +
      content;

    aboutFoot.innerHTML =
      footer;
  }
}