export class InitFilterCategory {
  constructor(
    button
  ) {
    this.button = document.querySelectorAll(button)
  }

  filter(category) {
    this.button.forEach(item => item.classList.remove('active'))
    const listItems = document.querySelectorAll('.projects-list-item');
    listItems.forEach(item => {
      item.classList.remove('hide')
      if (item.classList.contains('category-' + category)) {
        return;
      }
      item.classList.add('hide')
    });
  }

  init() {
    this.button.forEach(item => {
      item.addEventListener('click', () => {
        this.filter(item.getAttribute('data-category'))
        item.classList.add('active')
      }
      )
    });
  }
}