import cursor from '../../components/global/cursor.html';
import preloader from '../../components/global/preloader.html';
import cookies from '../../components/global/cookies.html';

import header from '../../components/layout/header.html';

import hero from '../../components/project/hero.html';
import about from '../../components/project/about.html';
import content from '../../components/project/content.html';
import comparison from '../../components/project/comparison.html';
import gallery from '../../components/project/gallery.html';
import related from '../../components/project/related.html';

import footer from '../../components/layout/footer.html';

export const projectRoot = document.getElementById("projectRoot");
const projectHead = document.getElementById("projectHead");
const projectContent = document.getElementById("projectContent");
const projectFoot = document.getElementById("projectFoot");

export const initProjectPage = () => {
  if (projectRoot && projectHead && projectContent && projectFoot) {
    projectRoot.innerHTML =
      cursor +
      preloader +
      cookies;

    projectHead.innerHTML =
      header;

    projectContent.innerHTML =
      hero +
      about +
      content +
      comparison +
      gallery +
      related;

    projectFoot.innerHTML =
      footer;
  }
}