export const createFeaturedBlog = (post) => {
  const postFeatured = document.querySelector('[data-blog-featured]');

  let postContent = `
    <div class="blog-hero-featured-image">
      <img
        class="img-fluid defer"
        data-src="${post.image}"
        alt="${post.title}">
    </div>
    <div class="blog-hero-featured-content">
      <div class="blog-hero-featured-meta">
        <p class="blog-hero-featured-date">${post.date}</p>
      </div>
      <a href="./post.html?slug=${post.slug}">
        <h1 class="blog-hero-featured-title">${post.title}</h1>
      </a>
      <a
        data-hoverable
        href="./post.html?slug=${post.slug}"
        class="button">
        Ler Artigo
        <div class="button-arrow"></div>
      </a>
    </div>
    `;

  if (postFeatured)
    postFeatured.innerHTML = postContent
}