import { addButton } from "../../vendors/util";

export const createAbout = (about) => {
  const aboutContent = document.querySelector('[data-about-content]');
  const aboutContentWrapper = document.createElement('div');
  const aboutButton = document.querySelector('[data-about-button]');

  aboutContentWrapper.innerHTML = about.content;
  aboutContent.prepend(aboutContentWrapper)

  addButton(aboutButton, about.button)
}