import { addButton } from "../../vendors/util";
import { createHeroSlide } from "./createHeroSlide";

export const createHero = (hero) => {
  const heroContent = document.querySelector('[data-hero-content]');
  const heroContentWrapper = document.createElement('div');
  const heroButton = document.querySelector('[data-hero-button]');
  const heroSlide = document.querySelector('[data-hero-slide]')

  heroContentWrapper.innerHTML = hero.featured;
  heroContent.prepend(heroContentWrapper)

  addButton(heroButton, hero.button)

  hero.gallery.forEach(slide => {
    const list = createHeroSlide(slide)
    if (heroSlide)
      heroSlide.appendChild(list)
  });
}