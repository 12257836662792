import cursor from '../../components/global/cursor.html';
import preloader from '../../components/global/preloader.html';
import cookies from '../../components/global/cookies.html';

import header from '../../components/layout/header.html';

import hero from '../../components/single/hero.html';
import content from '../../components/single/content.html';
import related from '../../components/single/related.html';

import footer from '../../components/layout/footer.html';

export const singleRoot = document.getElementById("singleRoot");
const singleHead = document.getElementById("singleHead");
const singleContent = document.getElementById("singleContent");
const singleFoot = document.getElementById("singleFoot");

export const initSinglePage = () => {
  if (singleRoot && singleHead && singleContent && singleFoot) {
    singleRoot.innerHTML =
      cursor +
      preloader +
      cookies;

    singleHead.innerHTML =
      header;

    singleContent.innerHTML =
      hero +
      content +
      related;

    singleFoot.innerHTML =
      footer;
  }
}