export const createProjects = (project, index) => {
  const projectsItem = document.createElement('div');
  projectsItem.classList.add('home-about-project');

  if (index % 2 !== 0) {
    projectsItem.classList.add('home-about-project-horizontal');
  }

  projectsItem.innerHTML = `
    <figure class="home-about-project-figure">
      <img
        class="img-fluid defer"
        data-src="${project.image}"
        alt="${project.title}">
    </figure>
    <a
      data-hoverable
      href="./projeto.html?slug=${project.slug}"
      class="button-fill">
      Ver Projeto
      <div class="button-arrow"></div>
    </a>
  `;

  return projectsItem;
}