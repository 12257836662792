import { baseUrl } from "../vendors/util"
import { defer } from "../modules/general/defer";
import { cursor } from "../modules/general/cursor";
import { addHero } from "../modules/project/addHero";
import { addAbout } from "../modules/project/addAbout";
import { addComparison } from "../modules/project/addComparison";
import { addGallery } from "../modules/project/addGallery";
import { createGallery } from "../modules/project/createGallery";
import { initProjectGallerySlide } from "../modules/initProjectGallerySlide";
import { initProjectComparison } from "../modules/initProjectComparison";
import InitHorizontalDrag from "../modules/horizontalDrag";
import { createRelated } from "../modules/project/createRelated";
import { addRelated } from "../modules/project/addRelated";
import { addTitlePage } from "../modules/general/addTitlePage";
import { preloaderClose } from "../animations/preloaderClose";

export const initFetchProject = () => {
  async function fetchProject(url) {
    const projectResponse = await fetch(url);
    const projectJSON = await projectResponse.json();

    let hero = {
      title: projectJSON.title,
      image: projectJSON.image
    }

    addTitlePage(hero.title)

    addHero(hero)
    addAbout(projectJSON.description)
    addComparison(projectJSON.comparison)

    projectJSON.gallery.forEach((item) => {
      const list = createGallery(item);
      addGallery(list);
    })

    if (projectJSON.related) {
      projectJSON.related.forEach((item) => {
        const list = createRelated(item);
        addRelated(list);
      })
    }
  }

  const urlParams = new URLSearchParams(window.location.search);
  const slug = urlParams.get('slug');

  fetchProject(baseUrl + 'project/' + slug).then(() => {
    cursor()

    initProjectGallerySlide();
    initProjectComparison();

    const projectRelatedDragList =
      new InitHorizontalDrag('[data-project-related-list]');

    projectRelatedDragList.init()

    defer()

    preloaderClose()
  })
}