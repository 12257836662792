import cursor from '../../components/global/cursor.html';
import preloader from '../../components/global/preloader.html';
import cookies from '../../components/global/cookies.html';

import header from '../../components/layout/header.html';

import hero from '../../components/projects/hero.html';
import list from '../../components/projects/list.html';

import footer from '../../components/layout/footer.html';

export const projectsRoot = document.getElementById("projectsRoot");
const projectsHead = document.getElementById("projectsHead");
const projectsContent = document.getElementById("projectsContent");
const projectsFoot = document.getElementById("projectsFoot");

export const initProjectsPage = () => {
  if (projectsRoot && projectsHead && projectsContent && projectsFoot) {
    projectsRoot.innerHTML =
      cursor +
      preloader +
      cookies;

    projectsHead.innerHTML =
      header;

    projectsContent.innerHTML =
      hero +
      list;

    projectsFoot.innerHTML =
      footer;
  }
}