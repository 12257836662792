export const createSocial = (social) => {
  const socialItem = document.createElement('li');
  socialItem.classList.add('social-item')

  socialItem.innerHTML = `
    <a
      data-hoverable
      target="_blank"
      title="${social.title}"
      href="${social.link}">
      ${social.icon}
    </a>
  `;

  return socialItem;
}