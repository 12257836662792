import cursor from '../../components/global/cursor.html';
import preloader from '../../components/global/preloader.html';
import cookies from '../../components/global/cookies.html';

import header from '../../components/layout/header.html';

import hero from '../../components/team/hero.html';
import list from '../../components/team/list.html';

import footer from '../../components/layout/footer.html';

export const teamRoot = document.getElementById("teamRoot");
const teamHead = document.getElementById("teamHead");
const teamContent = document.getElementById("teamContent");
const teamFoot = document.getElementById("teamFoot");

export const initTeamPage = () => {
  if (teamRoot && teamHead && teamContent && teamFoot) {
    teamRoot.innerHTML =
      cursor +
      preloader +
      cookies;

    teamHead.innerHTML =
      header;

    teamContent.innerHTML =
      hero +
      list;

    teamFoot.innerHTML =
      footer;
  }
}