export const createRelated = (item) => {
  const relatedItem = document.createElement('li');

  let relatedItemContent = `
    <article class="single-related-item">
      <a href="./post.html?slug=${item.slug}">
        <figure class="single-related-item-figure">
          <img
            class="img-fluid defer"
            data-src="${item.image}"
            alt="${item.title}">
        </figure>
        <div class="single-related-item-meta">
          <p class="single-related-item-date">${item.date}</p>
        </div>
        <h1 class="single-related-item-title">${item.title}</h1>
      </a>
    </article>
  `;

  relatedItem.innerHTML = relatedItemContent;

  return relatedItem;
}