import { preloaderClose } from "../animations/preloaderClose";
import { defer } from "../modules/general/defer";
import { addPagePosts } from "../modules/posts/addPagePosts";
import { createFeaturedBlog } from "../modules/posts/createFeaturedBlog";
import { createPagePosts } from "../modules/posts/createPagePosts";
import { baseUrl } from "../vendors/util"

export const initFetchPosts = () => {
  async function fetchPosts(url) {
    const projectsResponse = await fetch(url);
    const projectsJSON = await projectsResponse.json();

    createFeaturedBlog(projectsJSON[0])

    projectsJSON.slice(1).forEach((project) => {
      const list = createPagePosts(project);
      addPagePosts(list);
    })
  }

  fetchPosts(baseUrl + 'posts').then(() => {
    defer()
    preloaderClose()
  })
}