import cursor from '../../components/global/cursor.html';
import preloader from '../../components/global/preloader.html';
import cookies from '../../components/global/cookies.html';

import header from '../../components/layout/header.html';

import hero from '../../components/home/hero.html';
import about from '../../components/home/about.html';
import team from '../../components/home/team.html';
import blog from '../../components/home/blog.html';
import contact from '../../components/home/contact.html';

import footer from '../../components/layout/footer.html';

export const homeRoot = document.getElementById("homeRoot");
const homeHead = document.getElementById("homeHead");
const homeContent = document.getElementById("homeContent");
const homeFoot = document.getElementById("homeFoot");

export const initHomePage = () => {
  if (homeRoot && homeHead && homeContent && homeFoot) {
    homeRoot.innerHTML =
      cursor +
      preloader +
      cookies;

    homeHead.innerHTML =
      header;

    homeContent.innerHTML =
      hero +
      about +
      team +
      contact;
    // contact +
    // blog;

    homeFoot.innerHTML =
      footer;
  }
}