import { gsap, Power3 } from "gsap";

export const cursor = () => {
  const cursor = document.querySelector('[data-cursor]');
  const hoverables = document.querySelectorAll('[data-hoverable]');

  gsap.set('.cursor', { xPercent: -50, yPercent: -50 });

  document.addEventListener('mousemove', (e) => {
    gsap.to(cursor, { x: e.clientX, y: e.clientY, duration: .2 });
  });

  const onMouseHover = () => cursorHover.play();
  const onMouseHoverOut = () => cursorHover.reverse();

  for (let i = 0; i < hoverables.length; i++) {
    hoverables[i].addEventListener('mouseenter', onMouseHover);
    hoverables[i].addEventListener('mouseleave', onMouseHoverOut);
  }

  let cursorHover = gsap.timeline({ paused: true });

  cursorHover.to(cursor, {
    duration: .05,
    scale: 2,
    borderColor: '#eee',
    backgroundColor: '#eee',
    ease: Power3.easeInOut,
  })
}