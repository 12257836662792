import cursor from '../../components/global/cursor.html';
import preloader from '../../components/global/preloader.html';
import cookies from '../../components/global/cookies.html';

import header from '../../components/layout/header.html';

import hero from '../../components/blog/hero.html';
import list from '../../components/blog/list.html';

import footer from '../../components/layout/footer.html';

export const blogRoot = document.getElementById("blogRoot");
const blogHead = document.getElementById("blogHead");
const blogContent = document.getElementById("blogContent");
const blogFoot = document.getElementById("blogFoot");

export const initBlogPage = () => {
  if (blogRoot && blogHead && blogContent && blogFoot) {
    blogRoot.innerHTML =
      cursor +
      preloader +
      cookies;

    blogHead.innerHTML =
      header;

    blogContent.innerHTML =
      hero +
      list;

    blogFoot.innerHTML =
      footer;
  }
}