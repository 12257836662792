export const createPageProjects = (project) => {
  const projectItem = document.createElement('li');
  projectItem.classList.add('projects-list-item', 'category-' + project.category);

  projectItem.innerHTML = `
    <article>
      <figure class="projects-list-item-figure">
        <img
          class="img-fluid defer"
          data-src="${project.image}"
          alt="${project.title}">
      </figure>
      <div class="projects-list-item-content">
        <h1 class="projects-list-item-title">${project.title}</h1>
        <p class="projects-list-item-description">${project.description}</p>
        <a
          data-hoverable
          href="./projeto.html?slug=${project.slug}"
          class="button">
          Ver Projeto
          <div class="button-arrow"></div>
        </a>
      </div>
    </article>
  `;

  return projectItem;
}