import IMask from "imask";

export const createMasks = () => {
  const phoneField = document.querySelector('[data-phone-field]');

  if (!phoneField) {
    return
  }

  const maskOptions = {
    mask: '+{55}(00) 00000.0000'
  };
  const mask = IMask(phoneField, maskOptions);
}