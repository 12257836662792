import { addHero } from "../modules/about/addHero";
import { baseUrl } from "../vendors/util"
import { defer } from "../modules/general/defer";
import { cursor } from "../modules/general/cursor";
import { addContent } from "../modules/about/addContent";
import { preloaderClose } from "../animations/preloaderClose";

export const initFetchAbout = () => {
  async function fetchAbout(url) {
    const aboutResponse = await fetch(url);
    const aboutJSON = await aboutResponse.json();

    let hero = {
      title: aboutJSON[0].title,
      image: aboutJSON[0].image
    }

    addHero(hero)
    addContent(aboutJSON[0].content[0], aboutJSON[0].content[0])
  }

  fetchAbout(baseUrl + 'about').then(() => {
    defer()
    cursor()
    preloaderClose()
  })
}