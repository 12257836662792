import { preloaderClose } from "../animations/preloaderClose";
import { defer } from "../modules/general/defer";
import { addPageTeam } from "../modules/team/addPageTeam";
import { createPageTeam } from "../modules/team/createPageTeam";
import { baseUrl } from "../vendors/util"

export const initFetchTeam = () => {
  async function fetchTeam(url) {
    const teamResponse = await fetch(url);
    const teamJSON = await teamResponse.json();

    teamJSON.forEach((person) => {
      const list = createPageTeam(person);
      addPageTeam(list);
    })
  }

  fetchTeam(baseUrl + 'team').then(() => {
    defer()
    preloaderClose()
  })
}