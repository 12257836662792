export const defer = () => {
  var imgDefer = document.querySelectorAll('.defer'),
    styleDefer = document.querySelectorAll('.defer-style');
  for (var i = 0; i < imgDefer.length; i++) {
    if (imgDefer[i].getAttribute('data-src')) {
      imgDefer[i].setAttribute('src', imgDefer[i].getAttribute('data-src'));
    }
  }
  for (var i = 0; i < styleDefer.length; i++) {
    if (styleDefer[i].getAttribute('data-style')) {
      styleDefer[i].style.backgroundImage = "url(" + styleDefer[i].getAttribute('data-style') + ")";
    }
  }
}