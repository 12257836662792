export const baseUrl = 'https://studiomou.com.br/api/wp-json/api/v1/'

export const homeUrl = './'

export const viewportMatches = width => window.matchMedia("(max-width: " + width + "px)").matches;

export const addButton = (buttonElement, data) => {
  if (!data.name) {
    buttonElement.style.display = 'none';
    return;
  }
  buttonElement.prepend(data.name)
  buttonElement.setAttribute('href', data.url);
}