export const addHero = (hero) => {
  const heroElement = document.querySelector('[data-about-hero]');

  let heroContent = `
    <h1 class="about-hero-title">
      ${hero.title}
    </h1>
    <figure class="about-hero-figure">
      <img
        class="img-fluid defer"
        data-src="${hero.image}"
        alt="${hero.title}">
    </figure>
  `;

  if (heroElement)
    heroElement.innerHTML = heroContent;
}